import { apiSlice } from '.';

import type { Template } from './templates';
import { SingleResponse } from './types';

export type Document = {
  id: string;
  url: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  account: string;
};

export interface CreateDocumentParams {
  recordId: string;
  objectType: string;
  templateId: Template['id'];
  isLive?: boolean;
}

const documentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createDocument: builder.mutation<Document, CreateDocumentParams>({
      query: ({ recordId, templateId, isLive = false, objectType }) => {
        const config = {
          url: `/templates/${templateId}/${objectType}/${recordId}`,
          method: 'POST',
          params: {},
        };

        config.params = isLive ? { active: true } : { preview: true };
        return config;
      },
      transformResponse: (response: SingleResponse<Document>) => response.data,
    }),
  }),
});

export const { useCreateDocumentMutation } = documentApi;
