import React, { useContext, createContext, useMemo, useState } from 'react';
import { useLazyGetPropertiesQuery } from '../../../app/api/properties';

import { Property, PropertyGroup } from '../../../app/api/properties';

import { DefinedBy, View } from '../types';
import { useDebounce } from 'primereact/hooks';
import { fuzzyMatch } from '../../../utils';

interface TokenContextProps {
  fetchQuery?: any;
  queryMeta?: any;
  data?: Property[];
  groups?: PropertyGroup[];
  isFetching?: boolean;
  propertyGroups?: PropertyGroup[];
  filterQuery?: string | null;
  setFilterQuery?: React.Dispatch<React.SetStateAction<string>>;
  isGrouped: boolean;
  setIsGrouped?: React.Dispatch<React.SetStateAction<boolean>>;
  view: View;
  setView?: React.Dispatch<React.SetStateAction<View>>;
  definedBy: DefinedBy;
  setDefinedBy?: React.Dispatch<React.SetStateAction<DefinedBy>>;
}

export const TokenContext = createContext<TokenContextProps>({ view: 'object', isGrouped: true, definedBy: 'all' });

export function TokensProvider({ children }: { children: React.ReactNode }) {
  const [fetchQuery, queryMeta] = useLazyGetPropertiesQuery();
  const [isGrouped, setIsGrouped] = useState<boolean>(true);
  const { properties, groups } = queryMeta?.data || {};
  const [filterQuery, debouncedFilterValue, setFilterQuery] = useDebounce('', 500);

  const [definedBy, setDefinedBy] = useState<DefinedBy>('all');
  const [view, setView] = useState<View>('object');
  const customProperties = useMemo(() => properties?.filter((prop) => prop.isCustom === true), [properties]);
  const hubspotProperties = useMemo(() => properties?.filter((prop) => prop.isCustom === false), [properties]);

  const filterStage1 = useMemo(
    () => (definedBy === 'all' ? properties : definedBy === 'user' ? customProperties : hubspotProperties),
    [definedBy, customProperties, hubspotProperties, properties],
  );

  const filterStage2 = useMemo(() => {
    if (debouncedFilterValue === '') return filterStage1;
    let result =
      filterStage1?.filter((prop) => {
        if (prop.relatedObjectProperties) return true;
        return fuzzyMatch(debouncedFilterValue, `${prop.label}:${prop.name}`);
      }) || [];

    return result?.map((prop) => {
      if (!prop.relatedObjectProperties) return prop;
      const filteredProps = prop.relatedObjectProperties.filter((p) =>
        fuzzyMatch(debouncedFilterValue, `${p.label}:${p.name}`),
      );
      return { ...prop, relatedObjectProperties: filteredProps || [] };
    });
  }, [filterStage1, debouncedFilterValue]);

  const filteredProperties = useMemo(() => filterStage2, [filterStage2]);

  const value: TokenContextProps = {
    fetchQuery,
    queryMeta,
    data: filteredProperties,
    groups,
    filterQuery,
    setFilterQuery,
    view,
    setView,
    isGrouped,
    definedBy,
    setIsGrouped,
    setDefinedBy,
  };

  return <TokenContext.Provider value={value}>{children}</TokenContext.Provider>;
}

export default TokensProvider;