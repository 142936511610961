import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAfter,
  clearLimit,
  clearRecordListParams,
  clearSearch,
  clearSort,
  selectRecordListParams,
  setAfter,
  setLimit,
  setRecordListParams,
  setSearch,
  setSort,
} from '../../../store/recordListParamsSlice';
import type { SetSortType, SetSearchType, RecordListParamsSliceState } from '../../../store/recordListParamsSlice';

export const useRecordListParams = () => {
  const recordListParams = useSelector(selectRecordListParams);

  const recordsSort = useMemo(
    () => ({
      sortBy: recordListParams.sortBy,
      sortOrder: recordListParams.sortOrder,
    }),
    [recordListParams],
  );

  const recordsSearch = useMemo(
    () => ({
      searchProperty: recordListParams.searchProperty,
      filterOperator: recordListParams.filterOperator,
      searchValue: recordListParams.searchValue,
    }),
    [recordListParams],
  );

  const dispatch = useDispatch();

  const dispatchParams = useCallback(
    (params: RecordListParamsSliceState) => dispatch(setRecordListParams(params)),
    [dispatch],
  );
  const dispatchClearParams = useCallback(() => dispatch(clearRecordListParams()), [dispatch]);
  const dispatchLimit = useCallback((limit: number) => dispatch(setLimit(limit)), [dispatch]);
  // React data table refers to an after value (or an offset value) as first, so it is named first here.
  const dispatchFirst = useCallback((first: string) => dispatch(setAfter(first)), [dispatch]);
  const dispatchClearLimit = useCallback(() => dispatch(clearLimit()), [dispatch]);
  const dispatchClearFirst = useCallback(() => dispatch(clearAfter()), [dispatch]);
  const dispatchSort = useCallback((sort: SetSortType) => dispatch(setSort(sort)), [dispatch]);
  const dispatchSearch = useCallback((search: SetSearchType) => dispatch(setSearch(search)), [dispatch]);
  const dispatchClearSearch = useCallback(() => dispatch(clearSearch()), [dispatch]);
  const dispatchClearSort = useCallback(() => dispatch(clearSort()), [dispatch]);

  return useMemo(
    () => ({
      recordListParams,
      recordsSort,
      recordsSearch,
      dispatchParams,
      dispatchClearParams,
      dispatchLimit,
      dispatchFirst,
      dispatchClearLimit,
      dispatchClearFirst,
      dispatchSort,
      dispatchSearch,
      dispatchClearSearch,
      dispatchClearSort,
    }),
    [
      recordListParams,
      recordsSort,
      recordsSearch,
      dispatchParams,
      dispatchClearParams,
      dispatchLimit,
      dispatchFirst,
      dispatchClearLimit,
      dispatchClearFirst,
      dispatchSort,
      dispatchSearch,
      dispatchClearSearch,
      dispatchClearSort,
    ],
  );
};

export default useRecordListParams;