import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { CustomSchemaType } from '../app/api/schemas';
import { schemasApi } from '../app/api/schemas';

const schemaAdapter = createEntityAdapter<CustomSchemaType>({
  selectId: (schema: CustomSchemaType) => schema.objectTypeId || schema.objectTypeName,
});

interface SchemaState extends ReturnType<typeof schemaAdapter.getInitialState> {
  objectTypeId: string | null;
  objectTypeName: string | null;
}

const initialState: SchemaState = schemaAdapter.getInitialState({ objectTypeId: null, objectTypeName: null });

export const objectSlice = createSlice({
  name: 'schema',
  initialState,
  reducers: {
    setSelectedSchema(
      state,
      action: PayloadAction<{ objectTypeId: string | null; objectTypeName: string | null } | CustomSchemaType>,
    ) {
      state.objectTypeId = action.payload.objectTypeId;
      state.objectTypeName = action.payload.objectTypeName;
    },
    deselectSchema(state) {
      state.objectTypeId = null;
      state.objectTypeName = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      schemasApi.endpoints.getAllSchemas.matchFulfilled,
      (state: SchemaState, action: PayloadAction<CustomSchemaType[]>) => {
        schemaAdapter.setAll(state, action.payload);
      },
    );
  },
});

export const { setSelectedSchema, deselectSchema } = objectSlice.actions;

export const selectObjectTypeId = (state: RootState) => state.schema.objectTypeId;
export const selectObjectTypeName = (state: RootState) => state.schema.objectTypeName;

export const {
  selectAll: selectAllSchema,
  selectById: selectSchemaById,
  selectIds: selectSchemaIds,
} = schemaAdapter.getSelectors((state: RootState) => state.schema ?? initialState);

export default objectSlice.reducer;
