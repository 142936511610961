import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import type { RecordListQueryParams } from '../app/api/records';

export interface RecordListParamsSliceState extends RecordListQueryParams {
  sortOrder?: 'ASCENDING' | 'DESCENDING';
}

const initialState: RecordListParamsSliceState = {
  sortBy: 'title',
  sortOrder: 'ASCENDING',
  limit: 25,
  after: '0',
};

export interface SetSortType {
  sortBy?: string;
  sortOrder?: 'ASCENDING' | 'DESCENDING';
}

export interface SetSearchType {
  searchProperty?: string;
  filterOperator?: string;
  searchValue?: string;
}

export const recordListParamsSlice = createSlice({
  name: 'recordListParams',
  initialState,
  reducers: {
    setRecordListParams: (_, action: PayloadAction<RecordListParamsSliceState>) => action.payload,
    clearRecordListParams: () => ({}),
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setAfter: (state, action: PayloadAction<string>) => {
      state.after = action.payload;
    },
    clearLimit: (state) => (state.limit = undefined),
    clearAfter: (state) => (state.after = undefined),
    setSort: (state, action: PayloadAction<SetSortType>) => {
      state.sortBy = action.payload.sortBy;
      state.sortOrder = action.payload.sortOrder;
    },
    setSearch: (state, action: PayloadAction<SetSearchType>) => {
      state.searchProperty = action.payload.searchProperty;
      state.filterOperator = action.payload.filterOperator;
      state.searchValue = action.payload.searchValue;
    },
    clearSearch: (state) => {
      state.searchValue = undefined;
      state.searchProperty = undefined;
      state.filterOperator = undefined;
    },
    clearSort: (state) => {
      state.sortBy = undefined;
      state.sortOrder = undefined;
    },
  },
});

export const {
  clearAfter,
  clearLimit,
  clearRecordListParams,
  clearSearch,
  clearSort,
  setAfter,
  setLimit,
  setRecordListParams,
  setSearch,
  setSort,
} = recordListParamsSlice.actions;

export const selectRecordListParams = (state: RootState) => state.recordListParams;

export default recordListParamsSlice.reducer;
