import { apiSlice } from '.';
import { SingleResponse } from './types';

export interface Property {
  name: string;
  label: string;
  isCustom?: boolean;
  type: string;
  relatedObjectType?: string;
  groupId?: string;
  relatedObjectProperties?: Property[];
  fieldType?: string;
}

export interface PropertyGroup {
  id: string;
  name: string;
  order: number;
  archived: boolean;
}

export interface GetPropertiesResponse {
  properties: Property[];
  groups: PropertyGroup[];
}

const properties = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getProperties: builder.query<GetPropertiesResponse, string>({
      query: (objectType) => `/properties/${objectType}`,
      providesTags: [{ id: 'List', type: 'Properties' }],
      transformResponse: (response: SingleResponse<GetPropertiesResponse>) => response.data,
    }),
  }),
});

export const { useGetPropertiesQuery, useLazyGetPropertiesQuery } = properties;
