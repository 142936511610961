import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeTemplateModal, openTemplateModal, selectTemplateModalVisible } from '../../../store/uiSlice';

export default function useTemplateUI() {
  const templateModalVisible = useSelector(selectTemplateModalVisible);
  const dispatch = useDispatch();

  const openModal = useCallback(() => {
    dispatch(openTemplateModal());
  }, [dispatch]);

  const closeModal = useCallback(() => {
    dispatch(closeTemplateModal());
  }, [dispatch]);

  return useMemo(
    () => ({ openModal, closeModal, templateModalVisible }),
    [openModal, closeModal, templateModalVisible],
  );
}
