import { Tooltip } from 'primereact/tooltip';

function Icon({ id, tooltip = '' }: { id: string; tooltip: string | void }) {
  if (!tooltip) return <></>;

  const tooltipId = `tooltip-${id}`;

  return (
    <>
      <Tooltip target={`#${tooltipId}`} className='z-50' />
      <i id={tooltipId} className='pi pi-info-circle mx-2' style={{ fontSize: '.8em' }} data-pr-tooltip={tooltip} />
    </>
  );
}

export default Icon;
