import { createContext, ReactNode, useEffect, useState, useMemo, useCallback } from 'react';
import { useGetAllSchemasQuery, schemasApi, CustomSchemaType } from '../../../app/api/schemas';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/authSlice';

type GetAllSchemasQueryResult = ReturnType<typeof schemasApi.useGetAllSchemasQuery>;

export type SetSelectedObjectType = (objectType: CustomSchemaType | CustomSchemaType['objectTypeId'] | null) => void;

type ObjectTypesContextType = {
  selectedObjectType: CustomSchemaType | null;
  setSelectedObjectType: SetSelectedObjectType;
  objectTypes: GetAllSchemasQueryResult;
};

export const ObjectTypesContext = createContext<ObjectTypesContextType>({
  selectedObjectType: null,
  setSelectedObjectType: () => {},
  objectTypes: { data: undefined, refetch: () => {} } as unknown as GetAllSchemasQueryResult,
});

export const ObjectTypesProvider = ({ children }: { children: ReactNode }) => {
  const user = useSelector(selectUser);
  const objectTypes = useGetAllSchemasQuery();
  const [selectedObjectTypeId, setSelectedObjectTypeId] = useState<CustomSchemaType['objectTypeId'] | null>(null);

  const setSelectedObjectType = useCallback(
    (objectType: CustomSchemaType | CustomSchemaType['objectTypeId'] | null) => {
      if (!objectType) return setSelectedObjectTypeId(null);

      if (typeof objectType === 'string') {
        setSelectedObjectTypeId(objectType);
      } else {
        setSelectedObjectTypeId(objectType.objectTypeId);
      }
    },
    [],
  );

  // Set initial object type
  useEffect(() => {
    if (!!selectedObjectTypeId || !user?.objectType || !objectTypes.data) return;
    const currentObjectType = objectTypes.data.find((schema) => schema.objectTypeName === user.objectType);
    if (currentObjectType) setSelectedObjectType(currentObjectType);
  }, [user, objectTypes, setSelectedObjectType, selectedObjectTypeId]);

  const selectedObjectType = useMemo(() => {
    return objectTypes.data?.find((schema) => schema.objectTypeId === selectedObjectTypeId) || null;
  }, [selectedObjectTypeId, objectTypes.data]);

  return (
    <ObjectTypesContext.Provider value={{ selectedObjectType, setSelectedObjectType, objectTypes }}>
      {children}
    </ObjectTypesContext.Provider>
  );
};

export default ObjectTypesProvider;
