import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Skeleton } from 'primereact/skeleton';
import { useCallback } from 'react';

export default function DataTableSkeleton() {
  const skeletonItems = Array.from({ length: 25 }, (_, i) => ({
    id: i,
  }));

  const renderBody = useCallback((height: number) => <Skeleton width={'100%'} height={`${height}px`} />, []);

  return (
    <div className='w-full'>
      <DataTable value={skeletonItems} className='p-datatable-sm' scrollable scrollHeight='100%' loading={false}>
        <Column header={'Title'} body={() => renderBody(45)} field='id' style={{ width: '25%' }} />
        <Column field='id' style={{ width: '15%' }} body={() => renderBody(35)} header={'Last Updated'} />
        <Column field='id' style={{ width: '15%' }} body={() => renderBody(35)} header={'Created'} />
        <Column field='id' style={{ width: '5%' }} body={() => renderBody(45)} />
      </DataTable>
    </div>
  );
}
