import { useCallback, useMemo } from 'react';
import { selectObjectTypeId, selectObjectTypeName, setSelectedSchema } from '../../../store/schemaSlice';
import { useDispatch } from 'react-redux';
import { selectSchemaById } from '../../../store/schemaSlice';
import { CustomSchemaType } from '../../../app/api/schemas';
import { useAppSelector } from '../../../hooks/reduxHooks';

export function useObject() {
  const objectTypeId = useAppSelector(selectObjectTypeId);
  const objectTypeName = useAppSelector(selectObjectTypeName);

  const dispatch = useDispatch();

  const setObjectType = useCallback(
    ({
      objectTypeName,
      objectTypeId,
    }:
      | {
          objectTypeName: string | null | undefined;
          objectTypeId: string | null | undefined;
        }
      | CustomSchemaType) => {
      dispatch(setSelectedSchema({ objectTypeName: objectTypeName || null, objectTypeId: objectTypeId || null }));
    },
    [dispatch],
  );

  const selectedSchema = useAppSelector((state) => selectSchemaById(state, objectTypeId || objectTypeName || ''));

  return useMemo(
    () => ({ setObjectType, selectedSchema, objectTypeName, objectTypeId }),
    [selectedSchema, setObjectType, objectTypeName, objectTypeId],
  );
}

export default useObject;