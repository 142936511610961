import { CustomSchemaType } from '../../../app/api/schemas';
import { SetSelectedObjectType } from '../contexts';
import { useObjectTypesContext } from './useObjectTypesContext';

export const useSelectedObjectType = (): [CustomSchemaType | null, SetSelectedObjectType] => {
  const { selectedObjectType: objectType, setSelectedObjectType } = useObjectTypesContext();
  return [objectType, setSelectedObjectType];
};

export default useSelectedObjectType;
