import { useCallback } from 'react';
import { Template } from '../../../app/api/templates';
import { useDocumentContext } from './useDocumentContext';

type CreateDocumentParams = {
  templateId: Template['id'];
  objectType: string;
  recordId: string;
};

type CreateDocumentOptions = {
  isLive?: boolean;
};

export function useDocument() {
  const { createDocument: _createDocument, document } = useDocumentContext();

  /**
   * Generate Document callbacks and effects
   */
  const createDocument = useCallback(
    async ({ templateId, objectType, recordId }: CreateDocumentParams, options?: CreateDocumentOptions) => {
      if (document.data) document.reset();
      const { isLive = true } = options || {};
      return await _createDocument({ templateId, objectType, recordId, isLive });
    },
    [_createDocument, document],
  );

  return { createDocument, document };
}
