import { Image } from 'primereact/image';

export default function EmptyState() {
  return (
    <div className='flex flex-col px-10 gap-8 h-full items-center justify-center'>
      <div className='flex-grow flex flex-col justify-center items-center gap-10 w-full pt-5'>
        <h1 className='text-2xl text-sky-800'>Getting Started</h1>
        <div className='flex gap-10 text-center text-primary-900 justify-around w-full max-w-4xl'>
          <div className='flex flex-col gap-5 items-center w-1/3' style={{ maxWidth: '200px' }}>
            <img src='/images/getting-started-step-1.svg' className='w-full' alt='Step-1' />
            <p>Select your template and record.</p>
          </div>
          <div className='flex flex-col gap-5  max-w-md w-1/3 items-center' style={{ maxWidth: '200px' }}>
            <img src='/images/getting-started-step-2.png' className='w-full' alt='Step-2' />
            <p>Preview and review your document</p>
          </div>
          <div className='flex flex-col gap-5  max-w-md w-1/3 items-center' style={{ maxWidth: '200px' }}>
            <img src='/images/getting-started-step-3.png' className='w-full' alt='Step-3' />
            <p>Generate your document and save it as a note</p>
          </div>
        </div>
      </div>
      <div className='flex-shrink py-5'>
        <Image src='./images/logo.svg' width='140px' className='' />
      </div>
    </div>
  );
}
