import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Link } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import config from '../../../config';
import { useAuth } from '../../../features/auth';
import { useCreatorMode } from '../../../hooks/uiHooks';
import { TemplateActiveStateNote, TemplateDropdown, useTemplates } from '../../../features/templates';
import { TokenListModal } from '../../../features/tokens';
import { GenerateDocumentButton } from '../../../features/documents';
import { ObjectTypeDropdown, useSelectedObjectType } from '../../../features/objectTypes';
import { RecordDropdown, useRecord } from '../../../features/records';
import { DebugButton } from '../../../features/debug';

export default function SideBar() {
  const [showTokens, setShowTokens] = useState<boolean>(false);
  const { user } = useAuth();
  const [creatorMode, { setCreatorMode }] = useCreatorMode();

  const { selectedRecord: record } = useRecord();
  const [objectType] = useSelectedObjectType();
  const { selectedTemplate: template } = useTemplates();

  const initialized = useRef<boolean>(false);

  /**
   * Initialize creator mode if user is admin
   */
  useEffect(() => {
    if (!initialized.current) {
      if (user?.isAdmin && !creatorMode) setCreatorMode?.(true);
      initialized.current = true;
    }
  }, [user, setCreatorMode, creatorMode]);

  /**
   * Main Component
   */
  return (
    <div className='flex flex-col gap-5 pr-4 pl-1 h-full justify-between'>
      <div className='flex flex-col gap-4'>
        {/* label includes a dropdown for the object type controls */}
        <label>
          <ObjectTypeDropdown
            style={{ border: 'none' }}
            dropdownIcon='pi pi-sort-down-fill'
            filter={true}
            filterPlaceholder='Filter...'
            // collapseIcon='pi pi-sort-up-fill'
            className='mt-2'
            pt={{
              input: { style: { fontSize: '16px', color: '#000', padding: '0' } },
              filterInput: { style: { padding: '5px 10px' } },
            }}
          />
        </label>

        {/* Record Controls */}
        <RecordDropdown />

        {/* line separates record controls and template controls */}
        <hr className='border-neutral-200 border-1 my-3' />

        {/* Template Controls */}
        <TemplateDropdown />

        <GenerateDocumentButton
          template={template || null}
          record={record}
          schema={objectType || null}
          className='flex-auto absolute top-4 right-8  z-[1000]'
        />

        <div className='flex flex-col gap-4'>
          {template && (
            <div className='flex flex-col gap-4'>
              {/* Notice about inactive template */}
              {template?.isActive === false && <TemplateActiveStateNote />}

              <GenerateDocumentButton
                template={template || null}
                record={record}
                schema={objectType || null}
                className='flex-auto'
                label='Refresh Preview'
                preview
                outlined
              />
            </div>
          )}
          {/* TOKENS BUTTON */}
          {creatorMode && (
            <Button onClick={() => setShowTokens(true)} outlined label={`View Tokens`} icon={<>&#123;...&#125;</>} />
          )}
        </div>
      </div>

      {/* Footer */}
      <div className='flex justify-around items-center'>
        <div className='flex flex-auto gap-3 items-center justify-start'>
          <label htmlFor='editor-mode' className='text-sm text-neutral-500'>
            Editor Mode
          </label>
          <InputSwitch
            inputId='editor-mode'
            checked={creatorMode}
            onChange={(e) => setCreatorMode?.(e.target.value)}
            style={{ scale: '.8' }}
          />
        </div>

        {/* Debug Info Download */}
        {creatorMode && (
          <div className='flex-auto'>
            <DebugButton template={template} />
          </div>
        )}

        <Link to={config.docsURL} className='text-primary-700' target='_blank'>
          <i className='pi pi-book mr-2' />
          Docs
        </Link>
      </div>

      {/* MODALS */}
      <TokenListModal visible={showTokens} onHide={() => setShowTokens(false)} />
    </div>
  );
}
