import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openRecordModal, closeRecordModal, selectRecordModalVisible } from '../../../store/uiSlice';

export function useRecordsUI() {
  const dispatch = useDispatch();
  const recordModalVisible = useSelector(selectRecordModalVisible);

  const openModal = useCallback(() => {
    dispatch(openRecordModal());
  }, [dispatch]);

  const closeModal = useCallback(() => {
    dispatch(closeRecordModal());
  }, [dispatch]);

  return useMemo(
    () => ({
      openModal,
      closeModal,
      recordModalVisible,
    }),
    [openModal, closeModal, recordModalVisible],
  );
}

export default useRecordsUI;
