import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

type UiState = {
  creatorMode: boolean;
  recordModalVisible: boolean;
  templateModalVisible: boolean;
};
const initialState: UiState = {
  creatorMode: false,
  recordModalVisible: false,
  templateModalVisible: false,
};

type CreatorModeToggleParam = boolean | void;

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    enableCreatorMode(state: UiState) {
      state.creatorMode = true;
    },
    disableCreatorMode(state: UiState) {
      state.creatorMode = false;
    },
    toggleCreatorMode(state: UiState, action: PayloadAction<CreatorModeToggleParam>) {
      if (action.payload) {
        state.creatorMode = action.payload;
      } else {
        state.creatorMode = !state.creatorMode;
      }
    },
    setCreatorMode(state: UiState, action: PayloadAction<boolean>) {
      state.creatorMode = action.payload;
    },
    openRecordModal(state: UiState) {
      state.recordModalVisible = true;
    },
    closeRecordModal(state: UiState) {
      state.recordModalVisible = false;
    },
    openTemplateModal(state: UiState) {
      state.templateModalVisible = true;
    },
    closeTemplateModal(state: UiState) {
      state.templateModalVisible = false;
    },
  },
});

export const {
  enableCreatorMode,
  disableCreatorMode,
  toggleCreatorMode,
  setCreatorMode,
  openRecordModal,
  closeRecordModal,
  openTemplateModal,
  closeTemplateModal,
} = uiSlice.actions;

export const selectCreatorMode = (state: RootState) => state.ui.creatorMode;
export const selectRecordModalVisible = (state: RootState) => state.ui.recordModalVisible;
export const selectTemplateModalVisible = (state: RootState) => state.ui.templateModalVisible;

export default uiSlice.reducer;
