import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ExpandedRecordType, recordsApi, useLazyGetRecordQuery } from '../../../app/api/records';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../store/authSlice';

// Extract the type of the entire recordsApi
type RecordsApiType = typeof recordsApi;

// Extract the type of the endpoints
type RecordsApiEndpoints = RecordsApiType['endpoints'];

// Extract the type of the getRecords query
type GetRecordsQuery = RecordsApiEndpoints['getRecords'];

// Extract the argument type for useLazyGetRecordsQuery
export type LazyGetRecordsQueryArg = Parameters<GetRecordsQuery['useLazyQuery']>[0];

// Extract the return type of useLazyGetRecordsQuery
export type LazyGetRecordsQueryResult = ReturnType<typeof recordsApi.useLazyGetRecordsQuery>;

// Now you can use these types in your component or context
export type SetSelectedRecordArgs = ExpandedRecordType['id'] | ExpandedRecordType | null;
export type SetSelectedRecord = (record: SetSelectedRecordArgs) => void;

type RecordsContextType = {
  fetchRecords: LazyGetRecordsQueryResult[0];
  records: LazyGetRecordsQueryResult[1];
  setSelectedRecord: SetSelectedRecord;
  selectedRecord: ExpandedRecordType | null;
};

export const RecordsContext = createContext<RecordsContextType>({
  fetchRecords: (() => ({ data: undefined })) as unknown as LazyGetRecordsQueryResult[0],
  records: { data: undefined } as unknown as LazyGetRecordsQueryResult[1],
  setSelectedRecord: () => {},
  selectedRecord: null,
});

export const RecordsProvider = ({ children }: { children: ReactNode }) => {
  const user = useSelector(selectUser);
  const [selectedRecordId, setSelectedRecordId] = useState<string | null>(null);
  const [trigger, result] = recordsApi.useLazyGetRecordsQuery();
  const [loadRecord, recordResults] = useLazyGetRecordQuery();

  const setSelectedRecord = useCallback<SetSelectedRecord>((record) => {
    if (typeof record === 'string') {
      setSelectedRecordId(record);
    } else if (record === null) {
      setSelectedRecordId(null);
    } else {
      setSelectedRecordId(record.id);
    }
  }, []);

  const selectedRecord = useMemo(() => {
    if (!result.data) return null;
    return result.data.data.find((record: ExpandedRecordType) => record.id === selectedRecordId) ?? null;
  }, [selectedRecordId, result.data]);

  const loadInitialRecord = useCallback(async () => {
    if (!user?.objectId || !user?.objectType) return;
    const result = await loadRecord({ objectType: user.objectType, recordId: user.objectId });
    if (result.data) setSelectedRecordId(result.data.id);
  }, [loadRecord, user]);

  const loadRecords = useCallback(
    async ({ limit = 25, after = '0' } = {}) => {
      return trigger({ objectType: user?.objectType ?? '', queryParams: { limit, after } });
    },
    [trigger, user?.objectType],
  );

  useEffect(() => {
    if (user?.objectType && !result.data && !result?.isFetching) {
      loadRecords();
    }
  }, [user, result, loadRecords]);

  useEffect(() => {
    if (selectedRecordId || !user?.objectId) return;
    loadInitialRecord();
  }, [selectedRecordId, user, loadInitialRecord]);

  return (
    <RecordsContext.Provider
      value={{
        fetchRecords: trigger,
        records: result,
        selectedRecord,
        setSelectedRecord: setSelectedRecord as SetSelectedRecord,
      }}
    >
      {children}
    </RecordsContext.Provider>
  );
};
