import { useContext } from 'react';
import { documentViewerContext } from '../contexts/DocumentViewerContext';

export function useDocumentViewerContext() {
  const context = useContext(documentViewerContext);

  if (!context) {
    throw new Error('useDocumentViewerContext must be used within a DocumentViewerProvider');
  }
  
  return context;
}