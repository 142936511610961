import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { apiSlice } from '../app/api';
import { templateReducer } from './templateSlice';
import authReducer from './authSlice';
import uiReducer from './uiSlice';
import recordReducer from './recordSlice';
import schemaReducer from './schemaSlice';
import recordListParamsReducer from './recordListParamsSlice';

export const store = configureStore({
  devTools: {
    name: 'Documint',
  },
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    template: templateReducer,
    schema: schemaReducer,
    record: recordReducer,
    recordListParams: recordListParamsReducer,
    ui: uiReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
