import { useMemo } from 'react';
import { Dropdown, DropdownProps } from 'primereact/dropdown';
import { useSelectedObjectType, useObjectTypes } from '../hooks';
import { mapObjectTypeToDropdownOption } from '../utils';

type ObjectDropdownProps = Omit<DropdownProps, 'options' | 'value'>;

export default function ObjectDropdown(props: ObjectDropdownProps) {
  const { objectTypes } = useObjectTypes();
  const [selectedObjectType, setSelectedObjectType] = useSelectedObjectType();
  const { className, filter = false, ...rest } = props;

  const value = useMemo(() => {
    return selectedObjectType ? mapObjectTypeToDropdownOption(selectedObjectType)?.value : null;
  }, [selectedObjectType]);

  const options = useMemo(() => {
    return objectTypes.data?.map(mapObjectTypeToDropdownOption) || [];
  }, [objectTypes.data]);

  return (
    <Dropdown
      options={options}
      value={value}
      filter={filter}
      loading={objectTypes.isLoading}
      onChange={(e) => {
        setSelectedObjectType(e.value);
      }}
      className={`p-inputtext-sm ${className}`}
      {...rest}
    />
  );
}
