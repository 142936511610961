// This function escapes special characters in a string to be used in a regular expression
export function escapeRegExp(str: string): string {
  return str
    .split('')
    .map((char) => (char.search(/[.*+?^${}()|[\]\\]/) !== -1 ? `\\${char}` : char))
    .join('');
}

// This function returns a regular expression string to be used in a search query
export function getSearchRegex(searchValue: string): string {
  return `/.*?(?:${escapeRegExp(searchValue)}).*?/i`;
}
