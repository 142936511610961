import { useCallback, useContext, useMemo } from 'react';
import { RecordListContext } from '../contexts/RecordListContext';

export default function useRecordListContext() {
  const context = useContext(RecordListContext);
  if (!context) {
    throw new Error('useRecordListContext must be used within a RecordsContextProvider');
  }

  const { setRecordList, recordList, meta, setMeta, setTotal, total } = context;

  const clearRecordList = useCallback(() => {
    setRecordList([]);
  }, [setRecordList]);

  return useMemo(
    () => ({ recordList, setRecordList, clearRecordList, meta, setMeta, total, setTotal }),
    [recordList, setRecordList, clearRecordList, meta, setMeta, total, setTotal],
  );
}
