import { createContext } from 'react';
import { ExpandedRecordType, useLazyGetRecordQuery } from '../../../app/api/records';

type RecordMetaType = {
  data: ExpandedRecordType | undefined;
  currentData: ExpandedRecordType | undefined;
  error: unknown;
  isUninitialized: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  refetch: () => Promise<{ data: ExpandedRecordType | undefined }>;
};

type RecordContextType = {
  record: RecordMetaType;
  fetchRecord: ({ recordId, objectType }: { recordId: string; objectType: string }, cache?: boolean) => RecordMetaType;
};

export const RecordContext = createContext<RecordContextType>({
  record: {
    data: undefined,
    currentData: undefined,
    error: undefined,
    isUninitialized: true,
    isLoading: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    refetch: async () => ({ data: undefined }),
  },
  fetchRecord: ({ recordId, objectType }: { recordId: string; objectType: string }) => ({} as RecordMetaType),
});

export function RecordProvider({ children }: { children: React.ReactNode }) {
  const [fetchRecord, recordMeta] = useLazyGetRecordQuery();
  
  return (
    <RecordContext.Provider
      value={{
        fetchRecord: fetchRecord as unknown as RecordContextType['fetchRecord'],
        record: recordMeta as unknown as RecordMetaType,
      }}
    >
      {children}
    </RecordContext.Provider>
  );
}
