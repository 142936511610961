import { Skeleton } from 'primereact/skeleton';

const TemplateListGridItemSkeleton = () => {
  return (
    <div className='border border-slate-200 flex flex-col justify-stretch surface-card rounded-lg bg-white overflow-hidden opacity-50'>
      <div className='flex flex-col items-center gap-3 p-2 bg-slate-300 grow-1 basis-full pb-0'>
        <Skeleton className='w-full rounded-none' height='170px' borderRadius='6px 6px 0 0' />
      </div>
      <div className='flex items-start justify-start px-3 py-4 basis-full z-10 border-t border-t-slate-200'>
        <Skeleton width='10px' height='10px' className='my-2 mx-2' shape='circle' />
        <div className='text-lg font-bold w-full'>
          <div className='mb-3'>
            <Skeleton width='150px' height='24px' borderRadius='30px' />
          </div>
          <div className='text-sm text-neutral-400 w-full'>
            <Skeleton width='80%' borderRadius='10px' height='14px' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateListGridItemSkeleton;
