import React, { useMemo, useRef } from 'react';
import TokenListFilters from './TokenListFilters';
import { useTokensContext } from '../../hooks';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Button } from 'primereact/button';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TokenTable } from '.';
import { groupProperties } from '../../utils';
import { GroupWithProperties } from '../../types';

const ObjectTokensList = () => {
  const { isGrouped, setIsGrouped, groups, queryMeta, data } = useTokensContext();
  const op = useRef<OverlayPanel>(null);
  const handleGroupedChange = (e: InputSwitchChangeEvent) => setIsGrouped?.(e.target.value);

  const objectProperties = useMemo(() => {
    return data?.filter((prop) => !prop.relatedObjectProperties);
  }, [data]);

  const propertyGroups: GroupWithProperties[] = useMemo(
    () => groupProperties(groups, objectProperties),
    [groups, objectProperties],
  );

  return (
    <div className='flex flex-col min-h-full'>
      <div className='flex gap-2 justify-between items-center py-1 '>
        <div className='flex gap-2 items-center'>
          <span className='text-sm'>Group Properties</span>
          <InputSwitch checked={isGrouped} onChange={handleGroupedChange} style={{ scale: '.8' }} />
        </div>
        <div>
          <Button link onClick={(e) => op?.current?.toggle(e)} size='small'>
            Filters
          </Button>
          <OverlayPanel ref={op} closeOnEscape>
            <TokenListFilters />
          </OverlayPanel>
        </div>
      </div>

      {/* RESULTS */}
      <div className='flex pb-0 flex-auto rounded-lg'>
        {isGrouped ? (
          <div className='flex flex-col min-h-full overflow-y-auto w-full rounded-lg'>
            {propertyGroups?.length > 0 ? (
              <Accordion multiple className='relative m-1'>
                {propertyGroups?.map((group) => (
                  <AccordionTab
                    key={group.id}
                    header={`${group.name} (${group.properties.length})`}
                    contentClassName='p-0'
                    pt={{
                      content: { style: { padding: 0, zIndex: 1 } },
                    }}
                    headerClassName='sticky top-0 z-10'
                  >
                    <TokenTable
                      data={group.properties}
                      isLoading={queryMeta?.isFetching}
                      // query={filterQuery || ''}
                      expandable={false}
                    />
                  </AccordionTab>
                ))}
              </Accordion>
            ) : (
              <div className='flex justify-center min-h-full items-center'>No tokens found</div>
            )}
          </div>
        ) : (
          <TokenTable
            data={objectProperties || []}
            isLoading={queryMeta.isLoading}
            // query={filterQuery || ''}
            expandable={false}
          />
        )}
      </div>
    </div>
  );
};

export default ObjectTokensList;
