import type { GroupWithProperties, PropertyNode } from '../types';
import type { Property, PropertyGroup } from '../../../app/api/properties';

export function nodeToParent(node: PropertyNode): PropertyNode {
  return { id: node.id, key: node.key, label: node.label, data: node.data };
}

// Maps a single HubSpot property to a single tree node
export function mapPropertyToNode(property: Property, parentNode?: PropertyNode): PropertyNode {
  const key = parentNode?.key ? `${parentNode.key}-` : '';

  const node: PropertyNode = {
    id: property.name,
    key: `${key}${property.name}`,
    label: property.label,
    data: { ...property },
    selectable: false,
  };

  const relatedProps = property.relatedObjectProperties || [];

  if (parentNode) node.data.parent = nodeToParent(parentNode);

  if (relatedProps?.length > 0) {
    node.useType = 'first';
    node.children = relatedProps.map((rProp, i) => mapPropertyToNode(rProp, node));
  }

  return node;
}

// Maps a list of properties to a list of nodes
export function mapPropertiesToNodes(properties: Property[]): PropertyNode[] {
  return properties.map((property) => mapPropertyToNode(property));
}

export function groupProperties(groups: PropertyGroup[] = [], properties: Property[] = []) {
  // Add 'properties' property to each group so we don't have to check for it later
  const results = groups?.map((group: PropertyGroup): GroupWithProperties => ({ ...group, properties: [] }));

  // All properties that don't have a group will be assigned to the catchall
  const catchall: GroupWithProperties = {
    id: 'no-group',
    name: 'No Group',
    properties: [],
    order: -3,
    archived: false,
  };

  properties.forEach((property) => {
    const group = results.find((group) => group.id === property.groupId);
    if (group) {
      group.properties.push(property);
    } else {
      catchall.properties.push(property);
    }
  });

  // Add catchall group to results
  results.push(catchall);

  return results.filter((group) => group.properties.length > 0).sort((a, b) => b.order - a.order);
}

export function snakeCaseToTitleCase(str: string) {
  return str
    .toLowerCase()
    .split('_')
    .map((s) => s.replace(s[0], s[0].toUpperCase()))
    .join(' ');
}

const defaultExport = { nodeToParent, mapPropertiesToNodes, mapPropertyToNode };

export default defaultExport;
