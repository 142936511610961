import { TemplateList } from '../../features/templates';

// Templates Page contains the Templates component (which is used elsewhere in a modal).
export default function TemplatePage() {
  return (
    <div className='w-screen h-screen'>
      {/* Templates Content */}
      <TemplateList />
    </div>
  );
}
