import { useCallback, useMemo, useRef } from 'react';
import { Button, ButtonProps } from 'primereact/button';
import { Template } from '../../../app/api/templates';
import { Toast } from 'primereact/toast';
import { useDocument } from '../hooks';

type GenerateDocumentProps = ButtonProps & {
  template: Template | null;
  record: { [key: string]: any } | null;
  schema: { [key: string]: any } | null;
  preview?: boolean;
};

const GenerateDocumentButton = (props: GenerateDocumentProps) => {
  const { template, schema, record, label = 'Generate', ...rest } = props;
  const { createDocument, document } = useDocument();
  const toast = useRef<Toast>(null);
  const isPreview = useMemo(() => 'preview' in props && props.preview !== false, [props]);

  const generateDocument = useCallback(async () => {
    try {
      const templateId = template?.id;
      const objectType = schema?.objectTypeId;
      const recordId = record?.id;

      if (!templateId || !objectType || !recordId) return;

      const document = await createDocument({ templateId, objectType, recordId }, { isLive: !isPreview });

      if (isPreview) return;
      
      toast.current?.show({
        severity: 'success',
        summary: 'Document Generated',
        detail: (
          <>
            <b>{document.name}</b> has been saved to the <b>Notes</b> section of this record.
          </>
        ),
        life: 5000,
      });
    } catch (error) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error was encountered while generating the document.',
        life: 3000,
      });
    }
  }, [createDocument, record, schema, template, isPreview]);

  return (
    <>
      <Button
        disabled={document?.isLoading || !schema || !record || !template}
        label={label}
        onClick={generateDocument}
        tooltip='Generate document and save as note'
        tooltipOptions={{ showDelay: 1000, position: 'left' }}
        loading={document?.isLoading}
        {...rest}
      />
      <Toast ref={toast} />
    </>
  );
};

export default GenerateDocumentButton;
