import React, { useCallback, useEffect } from 'react';
import { useTokensContext } from '../../hooks';
import { ObjectTokensList, AssociatedTokensList } from '.';
import config from '../../../../config';
import { Link, useNavigate } from 'react-router-dom';

import { SelectButton } from 'primereact/selectbutton';
import SearchButtonInput from '../../../../components/SearchButtonInput';

import { useSelectedObjectType } from '../../../objectTypes';
import { snakeCaseToTitleCase } from '../../utils/mappers';

const TokenList = () => {
  const { view, setView, fetchQuery, setFilterQuery } = useTokensContext();
  const [objectType] = useSelectedObjectType();
  const navigate = useNavigate();

  const handleQueryChange = useCallback(
    (value: string) => {
      setFilterQuery?.(value);
    },
    [setFilterQuery],
  );

  useEffect(() => {
    if (!objectType) return;
    fetchQuery?.(objectType.objectTypeId || '');
  }, [fetchQuery, objectType, navigate]);

  return (
    <>
      <div className='flex items-center justify-between gap-5' style={{ paddingTop: '1px' }}>
        {/* LEFT */}
        <div className='flex items-center basis-1/5'>
          <SearchButtonInput onChange={handleQueryChange} />
        </div>
        {/* CENTER */}
        <div className='flex gap-4 items-center flex-auto justify-center'>
          <SelectButton
            value={view}
            onChange={(e) => setView?.(e.target.value)}
            options={[
              {
                label: `${snakeCaseToTitleCase(objectType?.singularLabel || 'Record')} Properties`,
                value: 'object',
              },
              { label: 'Association Properties', value: 'associated' },
            ]}
            pt={{ button: { className: 'p-button-sm capitalize', style: { padding: '6px 10px' } } }}
          />
        </div>

        {/* RIGHT */}
        <div className='flex items-center basis-1/5 justify-end'>
          <Link to={config.docsURL} target='_blank' className='p-button p-button-text p-button-sm'>
            <i className='pi pi-question-circle mr-2' />
            Help
          </Link>
        </div>
      </div>
      <div>{view === 'object' ? <ObjectTokensList /> : <AssociatedTokensList />}</div>
    </>
  );
};

export default TokenList;
