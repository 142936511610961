import React from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { useTokensContext } from '../../hooks';
import { FieldDefinitionOptions } from '../../types';

const TokenListFilters = () => {
  const { definedBy, setDefinedBy } = useTokensContext();
  const fieldDefinitionOptions: FieldDefinitionOptions = [
    { value: 'all', label: 'All' },
    { value: 'hubspot', label: 'HubSpot' },
    { value: 'user', label: 'User' },
  ];

  return (
    <div className='flex flex-col gap-4 justify-between'>
      <div className='flex gap-4 justify-between items-center'>
        <h4 className='text-lg'>Defined by</h4>
        <SelectButton
          value={definedBy}
          onChange={(e) => setDefinedBy?.(e.target.value)}
          options={fieldDefinitionOptions}
          pt={{ button: { className: 'p-button-sm' } }}
        />
      </div>
    </div>
  );
};

export default TokenListFilters;
