import { createContext, ReactNode } from 'react';
import { useCreateDocumentMutation, Document } from '../../../app/api/documents';

type DocumentContextType = {
  createDocument: any;
  document: {
    data?: Document;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error?: unknown;
    reset: () => void;
  };
};

export const documentContext = createContext<DocumentContextType | null>(null);

export function DocumentProvider({ children }: { children: ReactNode }) {
  const [createDocument, documentMeta] = useCreateDocumentMutation();

  const value = {
    createDocument,
    document: documentMeta,
  };

  return <documentContext.Provider value={value}>{children}</documentContext.Provider>;
}

export default DocumentProvider;
