import { useCallback, useEffect } from 'react';

import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import { ObjectTypeDropdown, useSelectedObjectType } from '../../../objectTypes';
import RecordsTable from './table/RecordsTable';
import RecordSearchButtonInput from './table/RecordSearchButtonInput';

import { useRecordList, useRecordListParams } from '../../hooks';

  // See Paginator component for the shape of the page data.
  type PageDataType = { first: number; rows: number; page: number; pageCount: number };

export default function RecordList() {
  const [selectedSchema] = useSelectedObjectType();
  const { meta, total } = useRecordList();

  const { dispatchClearParams, dispatchFirst, dispatchLimit, recordListParams } = useRecordListParams();

  useEffect(() => {
    if (!recordListParams.limit) dispatchLimit(25);
  }, [recordListParams.limit, dispatchLimit]);

  // Called when user selects another page.
  const handlePageChange = useCallback(
    (pageData: PageDataType) => {
      if (!recordListParams || !selectedSchema) return;

      dispatchFirst(String(pageData.first));
      if (recordListParams.limit !== pageData.rows) dispatchLimit(pageData.rows);
    },
    [recordListParams, dispatchFirst, dispatchLimit, selectedSchema],
  );

  return (
    <div className='flex flex-col gap-4 h-full'>
      {/* HEADER */}
      <div className='grid grid-cols-3 py-2 px-1'>
        {/* LEFT */}
        <div className='flex justify-start items-center gap-3 '>
          <ObjectTypeDropdown filter={true} />
          <RecordSearchButtonInput />
        </div>

        {/* CENTER */}
        <div className='flex justify-center items-center'>
          <h4 className='text-2xl font-medium text-slate-600'>{`Select a ${
            selectedSchema?.singularLabel || 'Record'
          }`}</h4>
        </div>

        {/* RIGHT */}
        <div className='flex justify-end items-center gap-3 '>
          <Button
            size='small'
            onClick={dispatchClearParams}
            disabled={meta.isFetching}
            icon='pi pi-refresh'
            outlined
            severity='secondary'
            loading={meta.isFetching}
          />
        </div>
      </div>

      {/* CONTENT */}
      <div className='flex-1 flex flex-col gap-5 border items-center justify-between h-full w-full bg-slate-50 overflow-auto'>
        <RecordsTable />

        <Paginator
          first={Number(recordListParams.after) || 0}
          rows={recordListParams.limit}
          totalRecords={total || 0}
          onPageChange={(pageData: PageDataType) => handlePageChange(pageData)}
          rowsPerPageOptions={[25, 40, 50]}
        />
      </div>
    </div>
  );
}
