import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { useTokensContext } from '../../hooks';
import { useSelectedObjectType } from '../../../objectTypes';
import TokenList from './TokenList';

interface Props {
  visible: boolean;
  onHide: () => void;
}

const TokenListModal = ({ visible, onHide }: Props) => {
  const { fetchQuery, setFilterQuery } = useTokensContext();
  const [objectType] = useSelectedObjectType();
  const navigate = useNavigate();

  const handleHide = () => {
    setFilterQuery?.('');
    onHide?.();
  };

  useEffect(() => {
    if (!objectType) return;
    fetchQuery?.(objectType.objectTypeId || '');
  }, [fetchQuery, objectType, navigate]);

  return (
    <Dialog
      visible={visible}
      onHide={handleHide}
      style={{ width: 'calc(100vw - 20px)', height: 'calc(100vh - 20px)', maxHeight: 'none' }}
      header={<div className='text-center'>Tokens</div>}
      draggable={false}
      maskStyle={{ backdropFilter: 'blur(5px)' }}
      headerStyle={{ borderBottom: 'solid 1px #ddd', padding: '.7em 1em' }}
    >
      <TokenList />
    </Dialog>
  );
};

export default TokenListModal;
