import { useEffect, useState } from 'react';
import { Column } from 'primereact/column';
import { Property } from '../../../../../app/api/properties';
import { TreeTable } from 'primereact/treetable';
import { mapPropertiesToNodes, nodeToParent } from '../../../utils';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { PropertyNode, UseType } from '../../../types';
import TokenTableColumnBody from './TokenTableColumnBody';
import renderTypeColumnBody from './renderTypeColumnBody';
import renderDefinedByColumnBody from './renderDefinedByColumnBody';

interface Props {
  data: Property[];
  isLoading?: boolean;
  query?: string | null;
  expandable?: boolean;
}

const TokenTable = ({ data, isLoading, query, expandable = true }: Props) => {
  const [nodes, setNodes] = useState<PropertyNode[]>();

  useEffect(() => {
    setNodes(mapPropertiesToNodes(data));
  }, [data]);

  const handleUseTypeChange = (value: UseType, node: PropertyNode) => {
    setNodes((nodes) =>
      nodes?.map((n) => {
        if (n?.id !== node.id) return n;
        n.useType = value;
        if (!n?.children) return n;
        n.children.forEach((child) => {
          if (!child?.data?.parent) return;
          child.data.parent = nodeToParent(n);
        });
        return n;
      }),
    );
  };

  if (isLoading)
    return (
      <div className='flex h-full w-full justify-center items-center' style={{ height: 'calc(100vh - 220px)' }}>
        <LoadingSpinner text='Loading properties' />
      </div>
    );

  return (
    <TreeTable
      value={nodes}
      scrollable
      resizableColumns
      loading={isLoading}
      globalFilter={query}
      showGridlines
      pt={{ loadingOverlay: { style: { height: 'calc(100vh - 200px)', backgroundColor: 'rgba(255,255,255,.7)' } } }}
    >
      <Column
        field='label'
        header='Name'
        expander={expandable}
        className='overflow-x-auto whitespace-nowrap'
        sortable
        style={{ width: '400px' }}
      />
      <Column
        field='name'
        header='Token'
        style={{ width: '600px' }}
        bodyClassName='overflow-x-auto'
        body={(node) => <TokenTableColumnBody node={node} onUseTypeChange={handleUseTypeChange} />}
      />
      <Column field='type' header='Type' style={{ width: '150px' }} body={renderTypeColumnBody} />
      <Column field='isCustom' header='' style={{ width: '50px' }} align='center' body={renderDefinedByColumnBody} />;
    </TreeTable>
  );
};

export default TokenTable;
