import { useContext } from 'react';
import { RecordsContext } from '../contexts/RecordsContext';

export const useRecordsContext = () => {
  const context = useContext(RecordsContext);

  if (!context) {
    throw new Error('useRecordsContext must be used within a RecordsProvider');
  }
  return context;
};

export default useRecordsContext;