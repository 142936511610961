import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import {
  selectSelectedTemplateId,
  setSelectedTemplate,
  selectTemplateById,
  selectAllTemplates,
} from '../../../store/templateSlice';

import { type Template } from '../../../app/api/templates';

export function useTemplates() {
  const templates = useAppSelector(selectAllTemplates);
  const selectedTemplateId = useAppSelector(selectSelectedTemplateId);
  const dispatch = useAppDispatch();

  const selectTemplate = useCallback(
    (template: string | Template) => {
      dispatch(setSelectedTemplate(template));
    },
    [dispatch],
  );

  const selectedTemplate = useAppSelector((state) => selectTemplateById(state, selectedTemplateId || ''));

  return useMemo(
    () => ({ selectedTemplate, templates, selectTemplate }),
    [templates, selectedTemplate, selectTemplate],
  );
}
