import { useContext } from 'react';
import { ObjectTypesContext } from '../contexts/ObjectTypesContext';

export const useObjectTypesContext = () => {
  const context = useContext(ObjectTypesContext);

  if (!context) {
    throw new Error('useObjectTypesContext must be used within an ObjectTypesProvider');
  }

  return context;
};

export default useObjectTypesContext;
