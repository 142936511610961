import { useAppDispatch, useAppSelector } from './reduxHooks';
import { selectCreatorMode, setCreatorMode, toggleCreatorMode } from '../store/uiSlice';
import { useCallback, useMemo } from 'react';

export function useCreatorMode(): [
  boolean,
  { toggleCreatorMode: () => void; setCreatorMode: (value: boolean) => void },
] {
  const dispatch = useAppDispatch();
  const creatorMode: boolean = useAppSelector(selectCreatorMode);
  const toggle = useCallback(() => dispatch(toggleCreatorMode()), [dispatch]);
  const set = useCallback((value: boolean) => dispatch(setCreatorMode(value)), [dispatch]);
  return useMemo(() => [creatorMode, { toggleCreatorMode: toggle, setCreatorMode: set }], [creatorMode, toggle, set]);
}
