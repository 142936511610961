import { useContext } from 'react';

import { TokenContext } from '../contexts/TokensContext';

export const useTokensContext = () => {
  const context = useContext(TokenContext);

  if (!context) {
    throw new Error('useTokensContext must be used within a TokensProvider');
  }

  return context;
};

export default useTokensContext;
