import { useContext } from 'react';
import { documentContext } from '../contexts';

export const useDocumentContext = () => {
  const context = useContext(documentContext);

  if (!context) {
    throw new Error('useDocumentViewer must be used within a DocumentProvider');
  }
  
  return context;
};
