import { useEffect } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { PrimeReactProvider } from 'primereact/api';

// Views
import DashboardView from './views/Dashboard';
import ApiKeyView from './views/ApiKey';
import Unauthorized from './views/Unauthorized';
import Login from './views/Login';
import TemplatesPage from './views/TemplatesPage';
import Authorize from './views/Authorize';

import { ProtectedRoute } from './components/ProtectedRoute';
import { setToken } from './store/authSlice';
import { useDispatch } from 'react-redux';

const App = () => {
  const dispatch = useDispatch();

  // UseEffect to load the token if it exists.
  useEffect(() => {
    const search = new URLSearchParams(window.location.search);

    const token = search.get('session_token');
    if (token) {
      dispatch(setToken(token));
    }
  }, [dispatch]);

  const router = createBrowserRouter([
    { path: '/', element: <Login /> },
    { path: '/login', element: <Login /> },
    { path: '/unauthorized', element: <Unauthorized /> },
    {
      path: '/dashboard',
      element: <ProtectedRoute component={<DashboardView />} />,
    },
    // Templates page is different from the templates view as templates is contained in a page and as a modal.
    {
      path: '/templates',
      element: <ProtectedRoute component={<TemplatesPage />} />,
    },
    {
      path: '/api-key',
      element: <ProtectedRoute component={<ApiKeyView />} />,
    },
    {
      path: '/authorize',
      element: <Authorize />,
    },
    {
      path: '*',
      element: <Navigate to={'/'} />,
    },
  ]);

  return (
    <PrimeReactProvider>
      <RouterProvider router={router} />
    </PrimeReactProvider>
  );
};

export default App;
