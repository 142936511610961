import { useCallback, useEffect, useMemo } from 'react';
import { useLazyGetRecordsQuery } from '../../../app/api/records';
import useRecordListContext from './useRecordListContext';
import { useRecordListParams } from './useRecordListParams';
import { useSelectedObjectType } from '../../objectTypes';

import type { RecordListParamsSliceState } from '../../../store/recordListParamsSlice';

export const useRecordList = () => {
  const { recordListParams } = useRecordListParams();
  const [selectedObjectType] = useSelectedObjectType();
  const { clearRecordList, setMeta, setRecordList, setTotal, recordList, total, meta } = useRecordListContext();

  const [getRecords, { data, error, isFetching, isLoading, isSuccess }] = useLazyGetRecordsQuery();
  
  // sets the data from the request.
  useEffect(() => {
    setRecordList(data?.data || []);
    setTotal(data?.total || 0);
    setMeta({ error, isFetching, isLoading, isSuccess });
  }, [clearRecordList, setMeta, setRecordList, setTotal, data, error, isFetching, isLoading, isSuccess]);

  const fetchRecords = useCallback(
    async ({
      objectType,
      queryParams: { after, limit, sortBy, sortOrder, searchValue, searchProperty },
    }: {
      objectType: string;
      queryParams: RecordListParamsSliceState;
    }) => {
      try {
        await getRecords(
          {
            objectType,
            queryParams: {
              limit,
              after: String(after),
              sortBy,
              sortOrder,
              searchValue,
              searchProperty,
            },
          },
          true,
        );
      } catch (error: any) {
        console.error(error);
      }
    },
    [getRecords],
  );

  // Triggers a record batch to be fetched when the selected schema changes or the object type changes.
  useEffect(() => {
    fetchRecords({ objectType: selectedObjectType?.objectTypeId || '', queryParams: recordListParams });
  }, [fetchRecords, selectedObjectType, recordListParams]);

  return useMemo(() => ({ recordList, total, meta }), [recordList, total, meta]);
};
