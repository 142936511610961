// NOTE: This hook cannot share states between components. Each component
// subscribes to an instance of all states and actions contained within this hook.
// Create a request data provider to share states between components.

import { useCallback, useMemo } from 'react';
import { useLazyListTemplatesQuery } from '../../../app/api/templates';

export default function useTemplateList() {
  const [getTemplates, { data, error, isFetching, isSuccess, isLoading }] = useLazyListTemplatesQuery();

  const meta = useMemo(
    () => ({
      isFetching,
      isSuccess,
      isLoading,
      error,
    }),
    [error, isFetching, isSuccess, isLoading],
  );
  const templateData = useMemo(() => data, [data]);

  /**
   * @param {number} page - Page number start from 1
   * @param {number} limit - Number of items per page
   * @param {object} filters - Filter objects. Only supports equal operator
   * @param {object} sort - Sort object. Only supports ASCENDING and DESCENDING
   */
  const fetchTemplates = useCallback(
    (
      page?: number,
      limit?: number,
      filters?: { [key: string]: string },
      sort?: { field: string; order: 'ASCENDING' | 'DESCENDING' },
    ) => {
      getTemplates({
        page,
        limit,
        filters,
        sort: sort ? `${sort?.order === 'ASCENDING' ? '' : '-'}${sort?.field}` : undefined,
      });
    },
    [getTemplates],
  );

  return useMemo(() => ({ fetchTemplates, templateData, meta }), [fetchTemplates, templateData, meta]);
}
