import Icon from '../../Icon';
import { PropertyNode } from '../../../types';

const renderTypeColumnBody = (node: PropertyNode) => {
  const { type } = node.data;
  let tooltip;

  if (type === 'collection') tooltip = 'A collection is an array (list) of objects (group of properties)';

  return (
    <>
      {type} <Icon id={`${node.key}-2`} tooltip={tooltip} />
    </>
  );
};

export default renderTypeColumnBody;
