import { apiSlice } from './index';
import { getCurrentParams } from './utils';

import type { ListResponse, SingleResponse } from './types';

export type Template = {
  id: string;
  name?: string;
  documentNamePattern?: string;
  thumbnail?: { url: string };
  createdAt?: string;
  updatedAt?: string;
  isActive?: boolean;
  options?: {
    margin?: {
      top: string;
      right: string;
      bottom: string;
      left: string;
    };
  };
};

interface GetTemplateParams {
  id: string;
  query?: TemplatesQuery;
}

export interface TemplatesQuery {
  select?: string;
  limit?: number;
  page?: number;
  // The endpoint needs to spread filters as query params
  filters?: { [key: string]: string }; // e.g. // ?propertyName=propertyValue
  sort?: string; // e.g. -name, name
}

interface UpdateTemplateParams {
  id: string;
  update: { [key: string]: any };
}

export type TemplateListResponse = ListResponse<Template>;

const defaultSelectQuery = { select: 'name,options,documentNamePattern,thumbnail,createdAt,updatedAt,isActive' };

export const templatesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTemplate: builder.query<Template, GetTemplateParams>({
      query: (params) => {
        const { id, query = {} } = params;
        const { select = 'name,options,documentNamePattern,thumbnail,createdAt,updatedAt,isActive' } = query;
        return {
          url: `/templates/${id}`,
          params: { ...query, select, ...getCurrentParams() },
        };
      },
      transformResponse: (response: SingleResponse<Template>) => {
        const { documentNamePattern = '', thumbnail } = response.data;
        // Add timestamp to thumbnail URL to bust cache
        let result: SingleResponse<Template>['data'] = {
          ...response.data,
          documentNamePattern,
          thumbnail: { ...thumbnail, url: thumbnail?.url + `?${Date.now()}` },
        };
        return result;
      },
      providesTags: (_, __, arg) => [{ id: arg.id, type: 'Template' as const }],
    }),

    listTemplates: builder.query<TemplateListResponse, TemplatesQuery | void>({
      query: (query) => {
        const { select, limit, sort, page, filters } = query || {};
        const allQueries: { [key: string]: string } = {
          ...defaultSelectQuery,
          ...getCurrentParams(),
          select,
          limit,
          sort,
          page,
          ...filters,
        };
        return {
          url: `/templates`,
          params: allQueries,
        };
      },
      transformResponse: (response: TemplateListResponse) => {
        // Add timestamp to thumbnail URL to bust cache
        let result: ListResponse<Template> = {
          ...response,
          data: response?.data?.map(({ thumbnail, documentNamePattern = '', ...rest }) => ({
            ...rest,
            documentNamePattern,
            thumbnail: { ...thumbnail, url: thumbnail?.url + `?${Date.now()}` },
          })),
        };

        return result; //templatesAdapter.setAll(initialState, result.data);
      },
      providesTags: (response) =>
        response
          ? [
              { id: 'LIST', type: 'Template' as const },
              ...response?.data?.map(({ id }) => ({ id, type: 'Template' as const })),
            ]
          : [],
    }),

    updateTemplate: builder.mutation<SingleResponse<Template>, UpdateTemplateParams>({
      query: ({ id, update }) => ({ url: `/templates/${id}`, method: 'PUT', body: update }),
      invalidatesTags: (_, __, arg) => [{ type: 'Template', id: arg.id }],
    }),
  }),
});

export const { useListTemplatesQuery, useUpdateTemplateMutation, useLazyListTemplatesQuery } = templatesApi;
