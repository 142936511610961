import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExpandedRecordType } from '../../../app/api/records';
import { removeRecord, selectRecordId, selectRecordObject, setRecord } from '../../../store/recordSlice';
import { useRecordContext } from './useRecordContext';

type LoadRecordType = {
  recordId: string;
  objectType: string;
};

/**
 * Tracks data for the selected record. Provides functions to set and remove the selected record.
 * @returns {selectedRecord, recordId, setSelectedRecord, removeSelectedRecord}
 */
export function useRecord() {
  const { fetchRecord, record } = useRecordContext();
  const selectedRecord = useSelector(selectRecordObject);

  // Record Id is important for the initial load of the record.
  const recordId = useSelector(selectRecordId);

  const dispatch = useDispatch();

  const setSelectedRecord = useCallback(
    (record: ExpandedRecordType | null) => {
      dispatch(setRecord(record));
    },
    [dispatch],
  );

  const removeSelectedRecord = useCallback(() => {
    dispatch(removeRecord());
  }, [dispatch]);

  const loadRecord = useCallback(
    ({ recordId, objectType }: LoadRecordType) => {
      if (!recordId || !objectType) return;
      return fetchRecord({ recordId, objectType });
    },
    [fetchRecord],
  );

  return { loadRecord, selectedRecord, recordId, setSelectedRecord, removeSelectedRecord, record };
}

export default useRecord;
