import { useCallback, useMemo } from 'react';
import { useLoginMutation } from '../../../app/api/auth';
import { selectToken, selectUser, setToken, setUser } from '../../../store/authSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/reduxHooks';
import { isEmpty } from 'lodash';

import { useSessionStorage } from 'primereact/hooks';
import type { User } from '../../../store/authSlice';

export function useAuth() {
  const [doLogin] = useLoginMutation();
  const dispatch = useAppDispatch();
  const token = useAppSelector(selectToken);
  const user = useAppSelector(selectUser);
  const storeUser = useSessionStorage<User | null>(null, 'sessionToken')[1];

  const getStoredToken = useCallback(() => {
    return window.sessionStorage.getItem('session_token');
  }, []);

  const setStoredToken = useCallback((token: string) => {
    window.sessionStorage.setItem('session_token', token);
  }, []);

  const _setToken = useCallback(
    (token: string) => {
      dispatch(setToken(token));
      setStoredToken(token);
    },
    [dispatch, setStoredToken],
  );

  const _setUser = useCallback(
    (user: User) => {
      dispatch(setUser(user));
      storeUser(user);
    },
    [dispatch, storeUser],
  );

  const getToken = useCallback(() => {
    if (token) return token;
    const storedToken = getStoredToken();
    if (!storedToken) return;
    _setToken(storedToken);
    return storedToken;
  }, [token, _setToken, getStoredToken]);

  const login = useCallback(
    async (providedToken?: string | null) => {
      // Get the session token
      const storedToken = getStoredToken();
      const currentToken = providedToken || token || storedToken;
      if (!currentToken) throw new Error('No session token');

      // Login
      if (user) return user;
      const { data } = await doLogin({ sessionToken: currentToken }).unwrap();
      if (isEmpty(data)) throw new Error('No user');
      _setToken(currentToken);
      _setUser(data);
      return data;
    },
    [doLogin, user, token, getStoredToken, _setToken, _setUser],
  );

  return useMemo(
    () => ({
      login,
      user,
      token,

      setToken: _setToken,
      setUser: _setUser,
      getToken,
    }),
    [login, user, token, _setToken, _setUser, getToken],
  );
}

export default useAuth;
