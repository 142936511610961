import React, { useCallback, useRef } from 'react';
import { DebugIcon } from '../../../components/icons';
import { useLazyGetDebugInfoQuery } from '../../../app/api';
import { Template } from '../../../app/api/templates';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

type DebugButtonProps = {
  template?: Template | null;
};

const DebugButton = (props: DebugButtonProps) => {
  const { template } = props;
  const [getDebugInfo, getDebugInfoMeta] = useLazyGetDebugInfoQuery();
  const debugInfoLinkRef = useRef<HTMLAnchorElement | null>(null);
  /**
   * Download debug management
   * */
  const handleDownloadDebug = useCallback(async () => {
    try {
      if (getDebugInfoMeta.isFetching) return;
      const element = debugInfoLinkRef.current;
      if (!element) return;
      const { data } = await getDebugInfo();
      const payload: { [key: string]: any } = { ...data };
      if (template) payload.template = template;
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(payload));
      element.setAttribute('href', dataStr);
      element.setAttribute('download', `debug-${data?.objectType || ''}-${data?.objectId}.json`);
      element.click();
    } catch (error) {
      console.log(error);
    }
  }, [getDebugInfo, getDebugInfoMeta, template]);

  const showConfirmDebugPrompt = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      confirmPopup({
        target: event.currentTarget,
        message: (
          <>
            Download debug info? <br />
            This is usually requested by Documint support.
          </>
        ),
        icon: 'pi pi-exclamation-triangle',
        accept: handleDownloadDebug,
        acceptLabel: 'Download',
        acceptIcon: 'pi pi-download',
        rejectLabel: 'Cancel',
      });
    },
    [handleDownloadDebug],
  );

  return (
    <>
      <ConfirmPopup acceptLabel='Download' />
      <a href='void' aria-label='ignore' ref={debugInfoLinkRef} className='hide'></a>
      <span className='cursor-pointer' onClick={showConfirmDebugPrompt}>
        {getDebugInfoMeta.isFetching ? <i className='pi pi-spin pi-spinner text-neutral-300' /> : <DebugIcon />}
      </span>
    </>
  );
};

export default DebugButton;
