import { ExpandedRecordType } from '../../../app/api/records';

// Not used currently, but could be used to sort records by a all properties of a record.
export function searchRecordsLocally(records: ExpandedRecordType[], searchString: string): ExpandedRecordType[] {
  const lowerSearchString = searchString.toLowerCase();
  const uniqueResults = new Set<ExpandedRecordType>(); // Use a Set to store unique records

  records.forEach((record) => {
    Object.values(record).forEach((value) => {
      if (typeof value === 'string' && value.toLowerCase().includes(lowerSearchString)) {
        uniqueResults.add(record); // Add record to the Set
        return;
      }
      if (Array.isArray(value)) {
        value.forEach((val) => {
          if (typeof val === 'string' && val.toLowerCase().includes(lowerSearchString)) {
            uniqueResults.add(record); // Add record to the Set
            return;
          }
        });
      }
    });
  });

  return Array.from(uniqueResults); // Convert Set back to array before returning
}

export function removeDuplicateRecords(records: ExpandedRecordType[]): ExpandedRecordType[] {
  return records.filter((obj, index, self) => index === self.findIndex((t) => t.id === obj.id));
}

export function renderContactTitle(record: ExpandedRecordType) {
  const { primaryDisplayProperty, secondaryDisplayProperties = {} } = record || {};
  const { lastname, email } = secondaryDisplayProperties;

  let result = '---';

  if (primaryDisplayProperty || lastname) {
    result = [primaryDisplayProperty, lastname].join(' ');
  } else if (email) {
    result = email;
  }

  return result;
}
