import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { ExpandedRecordType } from '../app/api/records';

type State = { recordId: string | null; object: ExpandedRecordType | null };

const initialState: State = { recordId: null, object: null };

export const recordSlice = createSlice({
  name: 'object',
  initialState,
  reducers: {
    // Cannot set recordId without setting the record object
    setRecord(state, action: PayloadAction<State['object']>) {
      state.object = action.payload;
      state.recordId = action.payload?.id || null;
    },
    removeRecord(state) {
      state.object = null;
      state.recordId = null;
    },
  },
});

export const { setRecord, removeRecord } = recordSlice.actions;

export const selectRecordId = (state: RootState) => state.record.recordId;
export const selectRecordObject = (state: RootState) => state.record.object;
export const selectRecord = (state: RootState) => state.record;

export default recordSlice.reducer;
