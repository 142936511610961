import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

export type User = {
  isAdmin: boolean;
  objectType: string;
  objectId: string;
};


export type AuthState = {
  token: string | null;
  user: User | null;
};

const initialState: AuthState = {
  token: null,
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<AuthState['token']>) {
      state.token = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
  },
});

export const { setToken, setUser } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;

export const selectToken = (state: RootState) => state.auth.token;

export default authSlice.reducer;
