import { Image } from 'primereact/image';

const Unauthorized = () => {
  return (
    <div className='w-full h-screen flex flex-col bg-primary-50 gap-6 overflow-y-auto py-6'>
      <div className='grow-1 flex justify-center items-center flex-auto'>
        <div className='flex flex-col justify-center text-center items-center gap-6 bg-white rounded-lg px-10 py-10 text-primary-700 shadow-lg'>
          <h2 className='text-2xl uppercase '>not authorized</h2>
          <i className='pi pi-shield' style={{ fontSize: '10em' }} />
          <p>Launch this app from HubSpot</p>
        </div>
      </div>
      <div className='flex grow-0 justify-center'>
        <Image src='./images/logo.svg' style={{ maxWidth: '170px' }} />
      </div>
    </div>
  );
};

export default Unauthorized;
