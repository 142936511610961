type FormatterFn = (propName: string) => string;

const createNumberFormatter = (format: string = '0,0') => {
  return (propName: string) => `$number ${propName} "${format}"`;
};

const createDateFormatter = (format: string = 'MM/DD/YYYY') => {
  return (propName: string) => `$date ${propName} "${format}"`;
};

const formatters: { [key: string]: FormatterFn } = {
  'number:number': createNumberFormatter(),
  'number:formatted': createNumberFormatter(),
  'number:unformatted': createNumberFormatter(),
  'number:percentage': createNumberFormatter('0%'),
  'number:currency': createNumberFormatter('$0,0.00'),
  'number:calculation_equation': createNumberFormatter(),
  'number:calculation_rollup': createNumberFormatter(),
  'number:calculation_score': createNumberFormatter(),
  'datetime:datetime': createDateFormatter('MM/DD/YYYY h:mm a'),
  'datetime:date': createDateFormatter('MM/DD/YYYY'),
  'datetime:calculation_read_time': createDateFormatter('MM/DD/YYYY h:mm a'),
  'datetime:calculation_rollup': createDateFormatter('MM/DD/YYYY h:mm a'),
};

export default formatters;
