import { toolbarPlugin, ToolbarPlugin } from '@react-pdf-viewer/toolbar';
import { createContext } from 'react';

type DocumentViewerContextType = {
  toolbarInstance: ToolbarPlugin;
};

export const documentViewerContext = createContext<DocumentViewerContextType | null>(null);

export function DocumentViewerProvider({ children }: { children: React.ReactNode }) {
  const toolbarInstance = toolbarPlugin();
  return <documentViewerContext.Provider value={{ toolbarInstance }}>{children}</documentViewerContext.Provider>;
}
