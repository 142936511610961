import { useContext } from 'react';
import { RecordContext } from '../contexts/RecordContext';

export const useRecordContext = () => {
  const context = useContext(RecordContext);

  if (!context) {
    throw new Error('useRecordContext must be used within a RecordContextProvider');
  }

  return context;
};

export default useRecordContext;
