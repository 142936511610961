import { Dialog } from 'primereact/dialog';
import useTemplateUI from '../../hooks/useTemplateUI';
import { TemplateList } from '../../components/list';

export default function TemplateModal() {
  const { closeModal, templateModalVisible } = useTemplateUI();

  return (
    <Dialog
      onHide={closeModal}
      headerStyle={{ paddingBlock: 7 }}
      modal
      style={{ width: '80%', height: '90%' }}
      visible={templateModalVisible}
    >
      <TemplateList />
    </Dialog>
  );
}
