import { createContext, SetStateAction, useState } from 'react';
import { ExpandedRecordType } from '../../../app/api/records';

import type { Dispatch } from 'react';

export interface RecordsMetaDataType {
  isLoading: boolean;
  error: unknown;
  isFetching: boolean;
  isSuccess: boolean;
}

export interface RecordListContextType {
  recordList: ExpandedRecordType[];
  setRecordList: Dispatch<SetStateAction<ExpandedRecordType[]>>;
  total: number;
  setTotal: Dispatch<SetStateAction<number>>;
  meta: RecordsMetaDataType;
  setMeta: Dispatch<SetStateAction<RecordsMetaDataType>>;
}

export const RecordListContext = createContext<RecordListContextType>({
  recordList: [],
  setRecordList: () => null,
  total: 0,
  setTotal: () => null,
  meta: { isLoading: false, error: null, isFetching: false, isSuccess: false },
  setMeta: () => null,
});

export function RecordListProvider({ children }: { children: React.ReactNode }) {
  const [recordList, setRecordList] = useState<ExpandedRecordType[]>([]);
  const [total, setTotal] = useState(0);
  const [meta, setMeta] = useState<RecordsMetaDataType>({
    isLoading: false,
    error: null,
    isFetching: false,
    isSuccess: false,
  });

  return (
    <RecordListContext.Provider value={{ recordList, setRecordList, total, setTotal, meta, setMeta }}>
      {children}
    </RecordListContext.Provider>
  );
}

export default RecordListProvider;
